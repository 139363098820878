<template>
  <div class="talentsData">
    <div class="topCard" style="margin-bottom: 20px">
      <el-card>
        <!-- 按字段查询 -->
        <el-form label-width="90px" class="ly-header__bg" style="margin: 0">
          <el-form-item label="日期：">
            <data-time @dateChange="dateClick" />
          </el-form-item>
          <el-form-item label="资讯类型：">
            <el-checkbox-button label="不限" @change="clickNo" v-model="dataTwo"
              >不限
            </el-checkbox-button>
            <el-checkbox-group
              v-model="queryInfo.informationTypes"
              @change="changeChildrenVal"
            >
              <el-checkbox-button
                v-for="(el, i) in codeData"
                :key="i + 'only'"
                :label="el.codeName"
              ></el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="筛选条件：" class="filterInfo">
            <el-tag>
              资讯类型：{{
                queryInfo.informationTypeOne.length > 0
                  ? queryInfo.informationTypeOne
                  : "不限"
              }}
            </el-tag>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <popover @popoverClick="popoverClick" />
          <!-- <el-button @click="multDelete" style="margin:0px">
            批量删除
            <i class="jr-iconjr-icon-delet" />
          </el-button>-->
          <el-button @click="ginsengClick">
            导入智参
            <i class="el-icon-caret-bottom" />
          </el-button>
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按发布时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnNames == 'article_release_Time' &&
                queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnNames == 'article_release_Time' &&
                queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
          <div style="margin-left: 50px">
            <el-checkbox v-model="checked" @change="changeChecked"
              >只查看未导入
            </el-checkbox>
          </div>
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="根据文本、标题进行搜索"
          v-model="queryInfo.textText"
          class="lager-input"
          @change="getNews"
        >
          <template slot="append">
            <div @click="getNews" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column type="selection" width="70"></el-table-column>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span>
              {{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="dataCompletion" label="数据完整度" sortable>
          <template v-slot="{ row }">
            <el-progress
              type="circle"
              :percentage="row.dataCompletion"
              sortable
              :stroke-width="12"
            ></el-progress>
          </template>
        </el-table-column>-->
        <el-table-column prop="imgLink" label="图片">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
              v-if="scope.row.imgLink"
            >
              <img
                :src="scope.row.imgLink"
                style="max-height: 300px; max-width: 300px"
              />
              <img
                slot="reference"
                :src="scope.row.imgLink"
                style="max-height: 100px; max-width: 100px; cursor: pointer"
                @click="editTalent(scope.row)"
              />
            </el-popover>
            <div @click="editTalent(scope.row)" style="cursor: pointer" v-else>
              <el-image
                :src="require('../../assets/img/pro.png')"
                class="headerImg"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="300">
          <template slot-scope="scope">
            <div class="scope_flex">
              <div
                @click="titleClick(scope.row)"
                class="scope_icon"
                style="cursor: pointer"
              >
                <span>{{ scope.row.title }}</span>
              </div>
              <div class="icon_success" v-if="scope.row.zcImportStatus == '1'">
                <i
                  class="el-icon-circle-check"
                  style="color: #00a907; font-size: 24px"
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="articleReleaseTime"
          label="发布时间"
        ></el-table-column>
        <!-- 富文本框内容不展示标签，只展示字符串 -->
        <el-table-column prop="text" label="简介" width="350">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              :content="stripTags(scope.row.text)"
            >
              <!-- <div slot="reference">
                <div v-if="scope.row.text !== null && scope.row.text !== ''">
                  {{ scope.row.text.substring(0, 100) }}
                  <span v-if="scope.row.text.length > 100">...</span>
                </div>
              </div> -->
              <div slot="reference">
                <div v-if="scope.row.text !== null && scope.row.text !== ''">
                  {{ stripTags(scope.row.text.substring(0, 100)) }}
                  <span v-if="scope.row.text.length > 100">...</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="crawlWebsite" label="爬取网站"></el-table-column>
        <!-- <el-table-column prop="positionType" label="关键词"></el-table-column> -->
        <el-table-column
          prop="informationType"
          label="资讯类型"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="creator"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <!--            <button-table-->
            <!--              @buttonClick="editTalent(scope.row)"-->
            <!--              content="查看"-->
            <!--              icon="jr-iconjr-icon-eye"-->
            <!--            />-->
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import DicTagButton from "@/components/DicTagButton.vue";
import ButtonTable from "../../components/ButtonTable.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DataTime from "../Product/com/dataTime.vue";
import Popover from "../../components/Popover.vue";
import UploadFile from "@/components/UploadFile.vue";
import { getNew, deleteNew, importInternal } from "@/api/news";
import { getDicRadioButton } from "@/api/common";
import { exportData } from "../../utils/tool";

export default {
  components: {
    ButtonTable,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    DataTime,
    Popover,
    UploadFile,
  },
  created() {
    this.getDicRadioButtons();
    this.queryInfo.columnNames = "create_time";
    this.getNews();
  },
  data() {
    return {
      checked: false,
      dataData: [],
      editTableObj: "",
      queryInfo: {
        zcImportStatus: "",
        pageSize: 10,
        pageNum: 1,
        order: 1,
        // 资讯类型
        informationTypes: [],
        informationTypeOne: "",
        columnNames: "article_release_Time,update_time",
        year: "",
        month: "",
        day: "",
        textText: "",
        id: [],
      },
      dataTwo: ["不限"],
      exportDatas: [],
      total: 0,
      idlist: [],
      // 搜索
      input2: "",
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
      dialogVisibleNews: false,
      codeData: [],
      text: "",
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    // 处理带有标签的字符串
    stripTags(html) {
      // 使用DOM解析器创建一个新的document
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, "text/html");

      // 获取所有的段落
      var ps = doc.getElementsByTagName("p");

      // 使用textContent属性获取每个段落的文本内容
      var text = Array.prototype.map
        .call(ps, function (p) {
          return p.textContent;
        })
        .join("\n");

      return text;
    },
    // 点击标题跳转到具体的页面
    titleClick(row) {
      window.open(row.titleLink, "_blank");
    },
    // 筛选未导入智参总后台的数据
    changeChecked(val) {
      if (val) {
        this.queryInfo.zcImportStatus = 0;
      } else {
        this.queryInfo.zcImportStatus = "";
      }
      this.getNews();
    },
    // 导入智参
    async ginsengClick() {
      if (this.idlist.length > 0) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const res = await importInternal(this.idlist);
        if (res.code === 200) {
          loading.close();
          setTimeout(() => {
            this.$message.success("导入成功");
            this.idlist = [];
            this.multDelete = [];
            this.getNews();
          }, 500);
        } else {
          loading.close();
          setTimeout(() => {
            this.$message.error("导入失败");
          }, 500);
        }
      } else {
        this.$message.warning("请选择您要导入的数据");
      }
    },
    async getDicRadioButtons() {
      const res = await getDicRadioButton({ code: "023" });
      if (res.code == 200) {
        this.codeData = res.data;
      }
    },
    // 按上传时间显示
    async uploadTime() {
      this.queryInfo.columnNames = "article_release_Time";
      if (this.queryInfo.order == 1) {
        this.queryInfo.order = 0;
        this.getNews();
      } else {
        this.queryInfo.order = 1;
        this.getNews();
      }
    },
    async getNews() {
      if (
        this.queryInfo.year == "请选择年份" &&
        this.queryInfo.month == "请选择月份" &&
        this.queryInfo.day == "请选择日"
      ) {
        this.queryInfo.year = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
      }
      const res = await getNew(this.queryInfo);
      if (res.code === 200) {
        this.dataData = res.data.list;
        this.total = res.data.total;
        this.dataData.forEach((el) => {
          if (el.dataCompletion) {
            el.dataCompletion = Number(el.dataCompletion.slice(0, -1));
          } else {
            el.dataCompletion = 0;
          }
        });
      }
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getNews();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getNews();
    },
    // 选中表格数据并赋值id选项
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id;
      });
      this.idlist = val.map((item) => {
        return item.id;
      });
    },
    // 跳转编辑页面
    editTalent(data) {
      // this.$router.push({
      //   name: 'newsInfo',
      //   params: {
      //     newsData: data,
      //   },
      // })
      // let queryData = JSON.stringify(data);
      let text = this.$router.resolve({
        path: "/news/newsInformation/newsInfo",
        query: {
          newsData: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    // 单个删除
    deleteTalent(data) {
      // this.$router.push({
      //   name: 'newsInfo',
      //   params: {
      //     newsData: data,
      //     newsEdit: 1,
      //   },
      // })
      // let queryData = JSON.stringify(data);
      let text = this.$router.resolve({
        path: "/news/newsInformation/newsInfo",
        query: {
          newsData: data.id,
          personType: 1,
          newsEdit: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    // 批量删除
    multDelete() {
      if (this.multipleSelection.length > 1) {
        this.$confirm("此操作将永久删除批量文件, 是否继续?", "提示").then(
          async () => {
            const res = await deleteNew(this.multipleSelection);
            if (res.code == 200) {
              this.getNews();
              this.multipleSelection = [];
            }
          }
        );
      } else {
        this.$message.error("批量删除必须大于一条");
      }
    },
    handleClose() {
      this.$refs.checkboxEnterprise.clear();
    },
    // 正序
    caretTop() {
      // this.queryInfo.order = 0;
      // this.getNews();
    },
    // 倒序
    caretBottom() {
      // this.queryInfo.order = 1;
      // this.getNews();
    },
    formatString(val) {
      if (!val) {
        return [];
      } else {
        return val.split(",");
      }
    },
    // 弹窗修改
    async saveItem() {},
    // 获取时间
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.year = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.getNews();
      } else if (obj.month == "0") {
        this.queryInfo.year = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.getNews();
      } else if (obj.day == "0") {
        this.queryInfo.year = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.getNews();
      } else {
        this.queryInfo.year = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.getNews();
      }
    },
    // 标签
    popoverClick(val) {
      if (val === "all") {
        // Object.assign(this.exportDatas, this.queryInfo)
        this.exportDatas = this.queryInfo;
      } else if (val == 0 && this.idlist.length) {
        Object.assign(this.exportDatas, this.queryInfo);
        // Object.assign(this.exportDatas, idlist : this.idlist,)
        this.exportDatas = {
          columnNames: this.queryInfo.columnNames,
          // order : this.queryInfo.order,
          year: this.queryInfo.year,
          month: this.queryInfo.month,
          day: this.queryInfo.day,
          idlist: this.idlist,
        };
      } else {
        this.exportDatas = {
          columnNames: this.queryInfo.columnNames,
          year: this.queryInfo.year,
          month: this.queryInfo.month,
          day: this.queryInfo.day,
          order: this.queryInfo.order,
          num: val,
        };
      }

      exportData(
        this.exportDatas, // 后台需要的参数
        "/News/exportExcel", // 路径
        "application/msexcel", // 下载的文件类型
        this.getNews, // 下载完之后调查询方法
        "新闻资讯.xls"
      );
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    changeChildrenVal() {
      // 解决筛选条件后点击第二条，换筛选条件后还在第二条问题
      this.queryInfo.pageNum = 1;
      this.dataTwo = [];
      this.queryInfo.informationTypeOne =
        this.queryInfo.informationTypes.join(",");
      this.getNews();
      if (!this.queryInfo.informationTypes.length > 0) {
        this.dataTwo = ["不限"];
      }
    },
    clickNo() {
      this.queryInfo.informationTypes = [];
      this.queryInfo.informationTypeOne = "";
      this.dataTwo = ["不限"];
      this.getNews();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-upload--text {
  width: 20px;
}
.scope_flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .scope_icon {
    flex: 1;
  }

  .icon_success {
    width: 24px;
    margin-left: 12px;
  }
}

.popover_ul {
  li {
    line-height: 25px;
  }

  li:hover {
    color: #1e9fff;
    cursor: pointer;
  }
}

.el-icon-caret-bottom {
  font-size: 14px;
}

/deep/ .el-card__body {
  padding: 10px;
}

// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  .upload_time {
    position: relative;
    color: #1f212b;
    font-size: 14px;
    margin-left: 20px;

    .el-icon-caret-top,
    .el-icon-caret-bottom {
      position: absolute;
      right: -16px;
      color: #1f212b;
    }

    .el-icon-caret-top {
      top: 0px;
    }

    .el-icon-caret-bottom {
      top: 6px;
    }
  }
}

/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}

.el-dropdown-menu {
  text-align: center;
  border: 1px solid #dbdbdb;
  width: 176px;
  height: 74px;
}

/deep/ .upload {
  padding: 12px 20px;
}

/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}

.caretTime {
  cursor: pointer;
}

.isActiveTime1 {
  color: #1e9fff !important;
}

.iconColor {
  color: #c0c4cc !important;
}

/deep/ .filterInfo .el-form-item__content {
  padding: 0px;
}

/deep/ .el-checkbox-group {
  display: inline-block;
  vertical-align: middle;
}
</style>
